import { createSlice, PayloadAction, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import axios  from "axios";

import { CLIENT_URL } from "config";
import { FetchData } from "models";

export type Dictionary = {
  ru: {
    originalWord: string;
    translation: string;
    id: string;
  }[];
  en: {
    originalWord: string;
    translation: string;
    id: string;
  }[];
}

type DictionaryState = {
  dictionary: Dictionary;
  isChanged: boolean;
  status: FetchData;
};

const defaultStatus = {
  isError: false,
  isLoading: false,
  error: undefined,
};

const loadingStatus = {
  isError: false,
  isLoading: true,
  error: undefined,
};

const errorStatus = {
  isError: false,
  isLoading: true,
  error: "Something wrong...",
};

const initialState: DictionaryState = {
  dictionary: {
    ru: [],
    en: [],
  },
  isChanged: false,
  status: {...defaultStatus},
};

export const fetchDictionary = createAsyncThunk("dictionary/fetchDictionary", (_, thunkAPI) => {
  const config = {
    url: `${CLIENT_URL}/api/dictionary/get`,
    method: "get",
    // withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const fetchExtract = createAsyncThunk("dictionary/fetchExtract", (_, thunkAPI) => {
  const config = {
    url: `${CLIENT_URL}/api/dictionary/extract`,
    method: "get",
    // withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const saveDictionary = createAsyncThunk("dictionary/saveDictionary", (dictionary: Dictionary, thunkAPI) => {
  const en: {[key: string]: string} = {};
  const ru: {[key: string]: string} = {};

  dictionary.en.forEach(item => { en[item.originalWord] = item.translation });
  dictionary.ru.forEach(item => { ru[item.originalWord] = item.translation });

  const config = {
    url: `${CLIENT_URL}/api/dictionary/set`,
    method: "put",
    data: { en, ru },
    // withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});
 
export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    addNewWord: (state, action: PayloadAction<{ word: string, transcription: string }>) => {
      const { word, transcription } = action.payload;
      const _id = nanoid();

      const wordPosition = state.dictionary["en"].findIndex(item => item.originalWord === word);

      if (wordPosition === -1) {
        const resultEN = {
          id: _id,
          originalWord: word,
          translation: word,
        };
        const resultRU = {
          id: _id,
          originalWord: word,
          translation: transcription,
        };
        state.dictionary["en"].push(resultEN);
        state.dictionary["ru"].push(resultRU);
      } else {
        state.dictionary["ru"][wordPosition].translation = transcription;
      }

      state.isChanged = true;
    },
    updateValue: (state, action: PayloadAction<{ lang: "en" | "ru", id: string, translation: string }>) => {
      const { lang, id, translation } = action.payload;
      const wordPosition = state.dictionary[lang].findIndex(item => item.id === id);
      state.dictionary[lang][wordPosition].translation = translation;
      state.isChanged = true;
    },
    setDictionary: (state, action: PayloadAction<Dictionary>) => {
      state.dictionary = action.payload;
      state.isChanged = true;
    },
  },
  extraReducers: {
    [fetchDictionary.fulfilled.type]: (state: DictionaryState, action: PayloadAction<{ru: { [key: string]: string }, en: { [key: string]: string }}>) => {
      const enKeys = Object.keys(action.payload.en);
      let enArr: any = [];
      enKeys.forEach(item => {
        enArr.push({
          id: nanoid(),
          originalWord: item,
          translation: action.payload.en[item]
        });
      });

      const ruKeys = Object.keys(action.payload.ru);
      let ruArr: any = [];
      ruKeys.forEach(item => {
        ruArr.push({
          id: nanoid(),
          originalWord: item,
          translation: action.payload.ru[item]
        });
      });

      state.status = {...defaultStatus};
      state.isChanged = false;
      state.dictionary = {
        en: enArr,
        ru: ruArr,
      };
    },
    [fetchDictionary.pending.type]: (state: DictionaryState) => {
      state.status = {...defaultStatus};
      state.isChanged = false;
    },
    [fetchDictionary.rejected.type]: (state: DictionaryState, action: PayloadAction<FetchData["error"]>) => {
      state.status = {...errorStatus, error: action.payload};
      state.isChanged = false;
    },
    [fetchExtract.fulfilled.type]: (state: DictionaryState, action: PayloadAction<{ru: { [key: string]: string }, en: { [key: string]: string }}>) => {
      state.status = {...defaultStatus};
      state.isChanged = false;
    },
    [fetchExtract.pending.type]: (state: DictionaryState) => {
      state.status = {...loadingStatus};
      state.isChanged = false;
    },
    [fetchExtract.rejected.type]: (state: DictionaryState, action: PayloadAction<FetchData["error"]>) => {
      state.status = {...errorStatus, error: action.payload};
      state.isChanged = false;
    },
    [saveDictionary.fulfilled.type]: (state: DictionaryState, action: PayloadAction<{ru: { [key: string]: string }, en: { [key: string]: string }}>) => {
      state.status = {...defaultStatus};
      state.isChanged = false;
    },
    [saveDictionary.pending.type]: (state: DictionaryState) => {
      state.status = {...loadingStatus};
      state.isChanged = false;
    },
    [saveDictionary.rejected.type]: (state: DictionaryState, action: PayloadAction<FetchData["error"]>) => {
      state.status = {...errorStatus, error: action.payload};
      state.isChanged = false;
    },
  },
});
