
import { useNavigate } from "react-router-dom";
import { GroupsRequiredFields, updateGroups, useAppDispatch } from "store";
import { GroupsForm } from "./form";

export function GroupsEditPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const updateHandler = ({
    fields,
    filters,
    anti_filters,
  }: {
    fields: GroupsRequiredFields,
    filters: { [key: string]: string }[],
    anti_filters: { [key: string]: string }[],
  }) => {
    dispatch(updateGroups({
      fields,
      filters,
      anti_filters,
    }))
      .then(result => {
        if ((result as any).error) return;
        navigate("/groups/edit/" + fields.alias);
      });
  };

  return (
    <GroupsForm compleatedCallback={updateHandler} />
  );
}
