import { createSlice, PayloadAction, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import axios  from "axios";

import { FetchData, FetchError } from "models";
import { fetchError } from "helpers";
import { SERVER_URL } from "config";

export type GroupsRequiredFields = {
  name: string;
  description: string;
  alias: string;
  defaultAlias: string | undefined;
  isPublished: boolean;
  filters: [{ "include_ids": string }]
  languageCode: "ru-RU" | "en-EN";
}

type Groups = GroupsRequiredFields[];

interface Fields {
  id: number | undefined;
  fullFilters: {
    id: string;
    type: "filters" | "anti-filters";
    filters: {
      name: string;
      items: {
        title: string;
        isChecked: boolean;
        forPrices: string;
      }[];
    }[];
  }[];
};

type GroupsState = {
  defaultFilters: {
    filters: {
      name: string;
      items: string[];
    }[];
    status: FetchData;
  };
  groups: Groups | undefined;
  fields: Fields & {
    status: FetchData;
  };
  status: FetchData;
};

const defaultStatus: FetchData = {
  isError: false,
  isLoading: false,
  error: undefined,
};

const initialState: GroupsState = {
  defaultFilters: {
    filters: [],
    status: defaultStatus,
  },
  fields: {
    id: undefined,
    fullFilters: [],
    status: defaultStatus,
  },
  groups: undefined,
  status: defaultStatus,
};

export const fetchGroupsDefaultFilters = createAsyncThunk("promo-code-create-or-edit/fetchDefaultFilters", (_, thunkAPI) => 
  axios({
    url: SERVER_URL + "/filters" + window.location.search,
    method: "get",
    withCredentials: true,
    headers: {
      "Accept-Language": "ru-RU"
    },
  })
    .then(result => {
      return result.data.filters.map((item: any) => {
        if (item.name !== "categories") return item;
        const innerItems = new Set<any>();

        item.items.forEach((items: any) => {
          items.items.forEach((value: string) => {
            innerItems.add(value);
          })
        })
        return {
          name: "categories",
          items: [...innerItems]
        };
      });
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    })
);

export const fetchGroups = createAsyncThunk("groups/fetchGroups", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/groups" + window.location.search,
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => { return result.data })
    .catch((error: FetchError) => thunkAPI.rejectWithValue(fetchError(error)))
});

export const createGroups = createAsyncThunk("groups/updateGroups", (props: {
  fields: GroupsRequiredFields,
  filters: { [key: string]: string }[],
  anti_filters: { [key: string]: string }[],
}, thunkAPI) => {
  const { fields, anti_filters, filters } = props;

  const _fields = {
    language_code: fields.languageCode,
    is_published: fields.isPublished,
    name: fields.name,
    description: fields.description,
    alias: fields.alias,
    filters: [
      ...filters,
      { "include_ids": fields.filters[0].include_ids }
    ],
    anti_filters: anti_filters,
  };

  const config = {
    url: SERVER_URL + "/admin/groups",
    method: "post",
    data: _fields,
    withCredentials: true,
  };

  return axios(config)
    .then(result => { return result.data })
    .catch((error: FetchError) => thunkAPI.rejectWithValue(fetchError(error)));
});

export const updateGroups = createAsyncThunk("groups/updateGroups", (props: {
  fields: GroupsRequiredFields,
  filters: { [key: string]: string }[],
  anti_filters: { [key: string]: string }[],
}, thunkAPI) => {

  const { fields, anti_filters, filters } = props;

  const _fields = {
    language_code: fields.languageCode,
    is_published: fields.isPublished,
    name: fields.name,
    description: fields.description,
    alias: fields.alias,
    filters: [
      ...filters,
      { "include_ids": fields.filters[0].include_ids }
    ],
    anti_filters: anti_filters,
  };

  console.log({_fields})

  const config = {
    url: SERVER_URL + "/admin/groups/" + fields.defaultAlias,
    method: "put",
    data: _fields,
    withCredentials: true,
  };

  return axios(config)
    .then(result => { return result.data })
    .catch((error: FetchError) => thunkAPI.rejectWithValue(fetchError(error)));
});

export const deleteGroups = createAsyncThunk("groups/deleteGroups", (alias: string, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/groups/" + alias,
    method: "delete",
    withCredentials: true,
  };

  return axios(config)
    .then(result => { return result.data })
    .catch((error: FetchError) => thunkAPI.rejectWithValue(fetchError(error)));
});

const fulfilled = (state: GroupsState) => {
  state.status.isLoading = false;
  state.status.isError = true;
  state.status.error = undefined;
};

const pending = (state: GroupsState) => {
  state.status.isLoading = true;
  state.status.isError = false;
  state.status.error = undefined;
};

const rejected = (state: GroupsState, action: PayloadAction<FetchData["error"]>) => {
  state.status.isLoading = false;
  state.status.isError = true;
  state.status.error = action.payload;
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setFullFilter: (state, action: PayloadAction<{
      fullFilters: {
        id: string;
        type: "filters" | "anti-filters";
        filters: {
          name: string;
          items: {
            title: string;
            isChecked: boolean;
            forPrices: string;
          }[];
        }[];
      }[];
    }>) => {
      state.fields.fullFilters = action.payload.fullFilters;
    },
    addFullFilter: (state) => {
      if (state.defaultFilters.filters.length === 0) throw new Error("defaultFilters cannot be empty");

      const parsedFilters = state.defaultFilters.filters.map((filter) => ({
        name: filter.name,
        items: filter.items.map(item => ({
          title: item,
          isChecked: false,
          forPrices: ""
        })),
      }));

      state.fields.fullFilters.push({
        id: nanoid(),
        filters: parsedFilters,
        type: "filters",
      });
    },
    changeFiltersType: (state, action: PayloadAction<{ id: string, type: "filters" | "anti-filters" }>) => {
      let searchPosition = -1;
      
      state.fields.fullFilters.map((filters, positon) => {
        if (filters.id === action.payload.id) { searchPosition = positon }
      });

      if (searchPosition !== -1) {
        state.fields.fullFilters[searchPosition] = {
          ...state.fields.fullFilters[searchPosition],
          type: action.payload.type,
        }
      }
    },
    deleteFiltersItem: (state, action: PayloadAction<string>) => {
      let searchPosition = -1;
      
      state.fields.fullFilters.map((filters, positon) => {
        if (filters.id === action.payload) { searchPosition = positon }
      });

      if (searchPosition !== -1) {
        state.fields.fullFilters.splice(searchPosition, 1);
      }
    },
    updateCheckboxFilters: (state, action: PayloadAction<{ id: string, name: string, title: string }>) => {
      let searchPosition = -1;
      
      state.fields.fullFilters.map((filters, positon) => {
        if (filters.id === action.payload.id) { searchPosition = positon }
      });

      if (searchPosition !== -1) {
        const tempFilters = state.fields.fullFilters[searchPosition].filters;

        let _filterPosition = -1;
        let _filterInnerPosition = -1;

        tempFilters.map((filter, filterPosition) => {
          if (filter.name === action.payload.name) {
            filter.items.map((filterInner, filterInnerPosition) => {
              if (filterInner.title === action.payload.title) {
                _filterPosition = filterPosition;
                _filterInnerPosition = filterInnerPosition;
              }
            });
          }
        })

        state.fields.fullFilters[searchPosition].filters[_filterPosition].items[_filterInnerPosition].isChecked = !state.fields.fullFilters[searchPosition].filters[_filterPosition].items[_filterInnerPosition].isChecked;
      }
    },
    updatePricesFilters: (state, action: PayloadAction<{ id: string, name: string, title: string, price: string }>) => {
      let searchPosition = -1;
      
      state.fields.fullFilters.map((filters, positon) => {
        if (filters.id === action.payload.id) { searchPosition = positon }
      });

      if (searchPosition !== -1) {
        const tempFilters = state.fields.fullFilters[searchPosition].filters;

        let _filterPosition = -1;
        let _filterInnerPosition = -1;

        tempFilters.map((filter, filterPosition) => {
          if (filter.name === action.payload.name) {
            filter.items.map((filterInner, filterInnerPosition) => {
              if (filterInner.title === action.payload.title) {
                _filterPosition = filterPosition;
                _filterInnerPosition = filterInnerPosition;
              }
            });
          }
        })

        state.fields.fullFilters[searchPosition].filters[_filterPosition].items[_filterInnerPosition].forPrices = action.payload.price;
      }
    },
    resetFields: (state) => {
      state.fields = initialState.fields;
    },
  },
  extraReducers: {
    [fetchGroupsDefaultFilters.fulfilled.type]: (state: GroupsState, action: PayloadAction<{
      name: string;
      items: string[];
    }[]>) => {
      state.defaultFilters.filters = action.payload;
      state.defaultFilters.status.isLoading = false;
      state.defaultFilters.status.isError = false;
      state.defaultFilters.status.error = undefined;
    },
    [fetchGroupsDefaultFilters.pending.type]: (state: GroupsState) => {
      state.defaultFilters.status.isLoading = true;
      state.defaultFilters.status.isError = false;
      state.defaultFilters.status.error = undefined;
    },
    [fetchGroupsDefaultFilters.rejected.type]: (state: GroupsState, action: PayloadAction<FetchData["error"]>) => {
      state.defaultFilters.status.isLoading = false;
      state.defaultFilters.status.isError = true;
      state.defaultFilters.status.error = action.payload;
    },
    [fetchGroups.fulfilled.type]: (state: GroupsState, action: PayloadAction<Groups>) => {
      state.groups = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchGroups.pending.type]: pending,
    [fetchGroups.rejected.type]: rejected,

    [createGroups.fulfilled.type]: fulfilled,
    [createGroups.pending.type]: pending,
    [createGroups.rejected.type]: rejected,

    [updateGroups.fulfilled.type]: fulfilled,
    [updateGroups.pending.type]: pending,
    [updateGroups.rejected.type]: rejected,

    [deleteGroups.fulfilled.type]: fulfilled,
    [deleteGroups.pending.type]: pending,
    [deleteGroups.rejected.type]: rejected,
  }
});