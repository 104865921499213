
import { useNavigate } from "react-router-dom";
import { GroupsRequiredFields, createGroups, useAppDispatch } from "store";
import { GroupsForm } from "./form";

export function GroupsCreatePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createHandler = ({
    fields,
    filters,
    anti_filters,
  }: {
    fields: GroupsRequiredFields,
    filters: { [key: string]: string }[],
    anti_filters: { [key: string]: string }[],
  }) => {
    dispatch(createGroups({
      fields,
      filters,
      anti_filters,
    }))
      .then(result => {
        if ((result as any).error) return;
        navigate("/groups");
      });
  };

  return (
    <GroupsForm compleatedCallback={createHandler} />
  );
}
